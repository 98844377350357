/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveCB, resolveImage } from "@dataResolvers";
import { Article as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query ArticleQuery($slug: [String]) {
    craft {
      entry(section: "blog", slug: $slug) {
        title
        url
        slug
        ... on Craft_blog_blog_Entry {
          uid
          #meta
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #hero
          postDate
          blogAuthor {
            ... on Craft_teamMembers_teamMembers_Entry {
              teamMember {
                ... on Craft_teamMember_teamMember_BlockType {
                  enabled
                  uid
                  image {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                  fullName
                }
              }
            }
          }
          commonContentBuilder {
            ... on Craft_commonContentBuilder_cbParagraph_BlockType {
              enabled
              id
              typeHandle
              paragraph
            }
            ... on Craft_commonContentBuilder_cbImage_BlockType {
              enabled
              id
              typeHandle
              image {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
            ... on Craft_commonContentBuilder_cbPullQuote_BlockType {
              enabled
              id
              typeHandle
              pullQuote
            }
            ... on Craft_commonContentBuilder_cbVideo_BlockType {
              enabled
              id
              typeHandle
              subheading
              heading
              description
              videoType
              videoId
            }
            ... on Craft_commonContentBuilder_cbLinks_BlockType {
              enabled
              id
              typeHandle
              links
            }
            ... on Craft_commonContentBuilder_cbPdfs_BlockType {
              enabled
              id
              typeHandle
              files {
                filename
                title
                slug
                status
                url
              }
            }
            ... on Craft_commonContentBuilder_cbCompanies_BlockType {
              enabled
              id
              typeHandle
              companyLogos {
                ... on Craft_companies_sponsors_Entry {
                  id
                  companyLogo {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                }
              }
            }
            ... on Craft_commonContentBuilder_cbCta_BlockType {
              enabled
              id
              typeHandle
              ctaTitle
              ctaSubtitle
              ctaLink {
                url
              }
              ctaBackgroundImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
            ... on Craft_commonContentBuilder_cbEmbed_BlockType {
              enabled
              id
              typeHandle
              code
              title
            }
            ... on Craft_commonContentBuilder_instagramPreview_BlockType {
              enabled
              id
              typeHandle
              extraPadding
            }
            ... on Craft_commonContentBuilder_cbContactCard_BlockType {
              enabled
              id
              typeHandle
              person {
                ... on Craft_teamMembers_teamMembers_Entry {
                  teamMember {
                    ... on Craft_teamMember_teamMember_BlockType {
                      enabled
                      image {
                        width
                        height
                        hasFocalPoint
                        slug
                        status
                        title
                        focalPoint
                        url
                      }
                      fullName
                      position
                      email
                      contactInfo {
                        linkedin
                      }
                    }
                  }
                }
              }
              cta
              linkLink {
                text
                url
              }
            }
          }
        }
      }
    }
  }
`;

const previewQuery = `query ArticleQuery($slug: [String]) {
  craft {
    entry(section: "blog", slug: $slug) {
      title
      url
      slug
      ... on Craft_blog_blog_Entry {
        uid
        #meta
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #hero
        postDate
        blogAuthor {
          ... on Craft_teamMembers_teamMembers_Entry {
            teamMember {
              ... on Craft_teamMember_teamMember_BlockType {
                enabled
                uid
                image {
                  width
                  height
                  hasFocalPoint
                  slug
                  status
                  title
                  focalPoint
                  url
                }
                fullName
              }
            }
          }
        }
        commonContentBuilder {
          ... on Craft_commonContentBuilder_cbParagraph_BlockType {
            enabled
            id
            typeHandle
            paragraph
          }
          ... on Craft_commonContentBuilder_cbImage_BlockType {
            enabled
            id
            typeHandle
            image {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
          ... on Craft_commonContentBuilder_cbPullQuote_BlockType {
            enabled
            id
            typeHandle
            pullQuote
          }
          ... on Craft_commonContentBuilder_cbVideo_BlockType {
            enabled
            id
            typeHandle
            subheading
            heading
            description
            videoType
            videoId
          }
          ... on Craft_commonContentBuilder_cbLinks_BlockType {
            enabled
            id
            typeHandle
            links
          }
          ... on Craft_commonContentBuilder_cbPdfs_BlockType {
            enabled
            id
            typeHandle
            files {
              filename
              title
              slug
              status
              url
            }
          }
          ... on Craft_commonContentBuilder_cbCompanies_BlockType {
            enabled
            id
            typeHandle
            companyLogos {
              ... on Craft_companies_sponsors_Entry {
                id
                companyLogo {
                  width
                  height
                  hasFocalPoint
                  slug
                  status
                  title
                  focalPoint
                  url
                }
              }
            }
          }
          ... on Craft_commonContentBuilder_cbCta_BlockType {
            enabled
            id
            typeHandle
            ctaTitle
            ctaSubtitle
            ctaLink {
              url
            }
            ctaBackgroundImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
          ... on Craft_commonContentBuilder_cbEmbed_BlockType {
            enabled
            id
            typeHandle
            code
            title
          }
          ... on Craft_commonContentBuilder_instagramPreview_BlockType {
            enabled
            id
            typeHandle
            extraPadding
          }
          ... on Craft_commonContentBuilder_cbContactCard_BlockType {
            enabled
            id
            typeHandle
            person {
              ... on Craft_teamMembers_teamMembers_Entry {
                teamMember {
                  ... on Craft_teamMember_teamMember_BlockType {
                    enabled
                    image {
                      width
                      height
                      hasFocalPoint
                      slug
                      status
                      title
                      focalPoint
                      url
                    }
                    fullName
                    position
                    email
                    contactInfo {
                      linkedin
                    }
                  }
                }
              }
            }
            cta
            linkLink {
              text
              url
            }
          }
        }
      }
    }
  }
}`;

const dataResolver = ({ craft }) => {
  const {
    title,
    url,
    slug,
    // meta
    metaTitle,
    metaDescription,
    metaImage,
    // content
    postDate,
    blogAuthor,
    commonContentBuilder,
    uid,
  } = craft.entry;

  const resolveAuthor = author => {
    author.map(a => {
      return {
        image: resolveImage(a.teamMember[0]?.image),
        name: a.teamMember[0]?.fullName,
      };
    });
  };

  return {
    uid,
    title,
    url,
    date: postDate,
    author: resolveAuthor(blogAuthor) || {
      image: null,
      name: "Economic Development Team",
    },
    contentBuilder: resolveCB(commonContentBuilder),
    meta: {
      title,
      url,
      slug,
      metaTitle,
      metaDescription,
      metaImage: resolveImage(metaImage),
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
